/*
__Seed builder__
  AUTO_GENERATED (Read only)
  Modify via builder
*/

export const SINGULARS =
{
  "actionLogs": "actionLog",
  "actionLog": "actionLog",
  "appInfos": "appInfo",
  "appInfo": "appInfo",
  "arrisBinnacles": "arrisBinnacle",
  "arrisBinnacle": "arrisBinnacle",
  "arrisCraneStatuses": "arrisCraneStatus",
  "arrisCraneStatus": "arrisCraneStatus",
  "arrisFailures": "arrisFailure",
  "arrisFailure": "arrisFailure",
  "arrisFiles": "arrisFile",
  "arrisFile": "arrisFile",
  "arrisForms": "arrisForm",
  "arrisForm": "arrisForm",
  "arrisOperators": "arrisOperator",
  "arrisOperator": "arrisOperator",
  "biMaintenanceKpis": "biMaintenanceKpi",
  "biMaintenanceKpi": "biMaintenanceKpi",
  "binnacleTokens": "binnacleToken",
  "binnacleToken": "binnacleToken",
  "contents": "content",
  "content": "content",
  "cranes": "crane",
  "crane": "crane",
  "craneCommercials": "craneCommercial",
  "craneCommercial": "craneCommercial",
  "crms": "crm",
  "crm": "crm",
  "elevations": "elevation",
  "elevation": "elevation",
  "evaluationCriteriaes": "evaluationCriteria",
  "evaluationCriteria": "evaluationCriteria",
  "evidences": "evidence",
  "evidence": "evidence",
  "inventories": "inventory",
  "inventory": "inventory",
  "inventoryExceptions": "inventoryException",
  "inventoryException": "inventoryException",
  "items": "item",
  "item": "item",
  "maintenances": "maintenance",
  "maintenance": "maintenance",
  "maintenanceCategories": "maintenanceCategory",
  "maintenanceCategory": "maintenanceCategory",
  "maintenanceEvidences": "maintenanceEvidence",
  "maintenanceEvidence": "maintenanceEvidence",
  "maintenanceFiles": "maintenanceFile",
  "maintenanceFile": "maintenanceFile",
  "maintenancePermses": "maintenancePerms",
  "maintenancePerms": "maintenancePerms",
  "maintenanceTypes": "maintenanceType",
  "maintenanceType": "maintenanceType",
  "onedriveAuths": "onedriveAuth",
  "onedriveAuth": "onedriveAuth",
  "operations": "operation",
  "operation": "operation",
  "operatorEvaluations": "operatorEvaluation",
  "operatorEvaluation": "operatorEvaluation",
  "orders": "order",
  "order": "order",
  "parts": "part",
  "part": "part",
  "preventiveMaintenances": "preventiveMaintenance",
  "preventiveMaintenance": "preventiveMaintenance",
  "preventiveMaintenanceCategories": "preventiveMaintenanceCategory",
  "preventiveMaintenanceCategory": "preventiveMaintenanceCategory",
  "preventiveMaintenanceEvidences": "preventiveMaintenanceEvidence",
  "preventiveMaintenanceEvidence": "preventiveMaintenanceEvidence",
  "reminders": "reminder",
  "reminder": "reminder",
  "residentReports": "residentReport",
  "residentReport": "residentReport",
  "schedules": "schedule",
  "schedule": "schedule",
  "securityCategories": "securityCategory",
  "securityCategory": "securityCategory",
  "securityChecks": "securityCheck",
  "securityCheck": "securityCheck",
  "securityCheckTypes": "securityCheckType",
  "securityCheckType": "securityCheckType",
  "securityRevisions": "securityRevision",
  "securityRevision": "securityRevision",
  "shippings": "shipping",
  "shipping": "shipping",
  "stockGroups": "stockGroup",
  "stockGroup": "stockGroup",
  "stockItems": "stockItem",
  "stockItem": "stockItem",
  "suggestions": "suggestion",
  "suggestion": "suggestion",
  "transportCompanies": "transportCompany",
  "transportCompany": "transportCompany",
  "users": "user",
  "user": "user",
};