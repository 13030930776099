import React, { useState } from "react";
import PropTypes from "prop-types";
import View from "components/preventive_maintenances/pending/List.view";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error";
import { formatFilters, formatSearchFilters } from "components/utils/filters";
import dayjs from 'dayjs';

const PreventiveMaintenancesPending = ({ search }) => {

    const [showAssignedOnly, setShowAssignedOnly] = useState(false);
    const zones = ["NORTH", "SOUTH", "SHALLOWS", "CENTRE"];

    let filters = formatFilters([
        formatSearchFilters(search, ["order.crane.series", "order.crane.model", "order.crane.number", "order.buildingName", "technician.firstName"])
    ]);

    filters = "(" + filters + ") AND status<>APPROVED AND status<>FINISHED";

    const qPreventiveMaintenances = useQuery(`
    {
        preventiveMaintenances {
            status
            zone
            startDate
            order{
                buildingName
                crane{
                    model
                    series
                    number
                }
            }
            technician{
                firstName
                lastName
            } 
        }
    }`, 
    filters);

    if (qPreventiveMaintenances.loading) return <Loading />;
    if (qPreventiveMaintenances.error) return <Error/>;

    const { preventiveMaintenances } = qPreventiveMaintenances.data;

    const getDaysUntilMaintenance = (startDate) => {
        const today = dayjs().startOf('day');
        const start = dayjs(startDate).startOf('day');
        const diff = start.diff(today, 'day');
        return diff;
    };

    return <View 
            getDaysUntilMaintenance={getDaysUntilMaintenance}
            preventiveMaintenances={preventiveMaintenances}
            setShowAssignedOnly={setShowAssignedOnly}
            showAssignedOnly={showAssignedOnly} 
            zones={zones}  />;
            
}

PreventiveMaintenancesPending.propTypes = {
    orderDate: PropTypes.string,
    pathName: PropTypes.string
};

export default PreventiveMaintenancesPending;