import React, { useState } from "react";
import PropTypes from "prop-types";
import ViewGeneral from "components/inventory/admin/InventoryReport.view";
import { useQuery } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import swal from "sweetalert";

const InventoryReport = () => {
    const path = window.location.pathname;
    const [activeTab, setActiveTab] = useState("TODOS");
    const [activeModel, setActiveModel] = useState(null); 

    const categories = ["TODOS", "TORRE", "PLUMA", "BASES", "MARCOS", "PIES", "JAULAS", "MECANISMOS"];
    const modelGroups = {
        "5LC+LC5211": ["5LC5010", "5LC3510", "5LC4510", "LC5211"],
        "10LC+11LC+LC1044": ["10LC140", "10LC110", "10LC1044", "11LC132", "11LC160", "LC1044"],
        "21LC+LC2070+LC2074": ["21LC170", "21LC290", "21LC335", "21LC400", "21LC450", "21LC550", "21LC660", "21LC750", "21LC1050", "LC2070", "LC2074"],
        "LCL": ["LCL"],
        "CM": [], 
        "ELEVADORES": [] 
    };

    const qInventories = useQuery(`{
        inventories{
            id
            totalParts
            inUse
            category
            parts {
                id
                name
                partId
                isManual
                craneModel
                inventoryExceptions {
                    status
                }
            }
        }
    }`);

    const [callDelete] = usePost("/inventories/delete_inventory", {
        onCompleted: () => {
            swal("¡Éxito!", "Inventario eliminado exitosamente.", "success").then(() => {
                window.location.reload();
            });
        },
        onError: () => {
            swal("¡Error!", "Error al eliminar el inventario.", "error");
        }
    });

    const handleDelete = (inventoryId) => {
        swal({
            title: "¿Estás seguro?",
            text: "Esta acción eliminará el inventario y todas las piezas asociadas.",
            icon: "warning",
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                callDelete({ inventory_id: inventoryId });
            }
        });
    };

    if (qInventories.loading) return <Loading />;
    if (qInventories.error) return "Error";

    const inventories = qInventories.data.inventories;

    if (path === "/inventory") {
        const handleTabClick = (category) => {
            setActiveTab(category);
            if (category === "TODOS") {
                setActiveModel(null);
            }
        };

        const handleModelClick = (modelGroup) => {
            setActiveModel(modelGroup);
        };

        const filterDataByCategoryAndModel = (category, modelGroup) => {
            const modelList = modelGroups[modelGroup] || [];

            return inventories
                .map((inventory) => ({
                    ...inventory,
                    parts: inventory.parts.filter(part => {
                        const categoryMatch = category === "TODOS" || inventory.category === category;
                        const modelMatch = !modelGroup || modelList.includes(part.craneModel);
                        return categoryMatch && modelMatch;
                    })
                }))
                .filter(inventory => inventory.parts.length > 0); 
        };

        const filteredData = filterDataByCategoryAndModel(activeTab, activeModel);

        return (
            <ViewGeneral 
                filteredData={filteredData.map(inventory => ({
                    ...inventory,
                    part: inventory.parts[0] 
                }))}
                handleTabClick={handleTabClick}
                handleModelClick={handleModelClick}
                activeTab={activeTab}
                activeModel={activeModel} 
                categories={categories} 
                models={Object.keys(modelGroups)} 
                handleDelete={handleDelete} 
            />
        );
    }
};

InventoryReport.propTypes = {};

export default InventoryReport;
