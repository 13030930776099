import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";

const FormView = ({ inventory = {}, onSubmit, onClose, error, step }) => (
    <div className="card h-100">

        {/* Header */}
        {step === 1 && (
            <>
                <div className="card-header">
                    <h3 className="card-header-title">
                        {inventory.id ? "Editar pieza" : "Nueva pieza"}
                    </h3>
                </div>
            </>
        )}

        {/* Body */}
        <div className="card-body">
            <div className="row">
                <div className="col">
                    <Formik
                        initialValues={{
                            name: inventory?.parts?.[0]?.name || "",
                            part_id: inventory?.parts?.[0]?.partId || "",
                            total_pieces: inventory?.totalParts || "",
                            category: inventory?.category || "",
                            crane_model: inventory?.craneModels || [], 
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                {step === 1 && (
                                    <>
                                        <div className="mb-3">
                                            <div className="row">
                                                {/* Nombre de la pieza */}
                                                <div className="col-md-6">
                                                    <div className="form-group form-group-default required">
                                                        <label className="control-label">Nombre de la pieza</label>
                                                        <Field
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            className="form-control"
                                                            required
                                                            defaultValue={values.name}
                                                        />
                                                        <div className="invalid-feedback">Requerido</div>
                                                    </div>
                                                </div>

                                                {/* ID de la pieza */}
                                                <div className="col-md-6">
                                                    <div className="form-group form-group-default required">
                                                        <label className="control-label">ID de la pieza</label>
                                                        <Field
                                                            type="text"
                                                            name="part_id"
                                                            id="part_id"
                                                            className="form-control"
                                                            required
                                                            defaultValue={values.part_id}
                                                        />
                                                        <div className="invalid-feedback">Requerido</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                {/* Número total de piezas */}
                                                <div className="col-md-6">
                                                    <div className="form-group form-group-default required">
                                                        <label className="control-label">Número total de piezas</label>
                                                        <Field
                                                            type="number"
                                                            name="total_pieces"
                                                            id="total_pieces"
                                                            className="form-control"
                                                            required
                                                            defaultValue={values.total_pieces}
                                                        />
                                                        <div className="invalid-feedback">Requerido</div>
                                                    </div>
                                                </div>

                                                {/* Categoría */}
                                                <div className="col-md-6">
                                                    <div className="form-group form-group-default required">
                                                        <label className="control-label">Categoría</label>
                                                        <Field
                                                            component="select"
                                                            name="category"
                                                            id="category"
                                                            className="form-control"
                                                            value={values.category}
                                                        >
                                                            <option value="">Seleccione una categoría</option>
                                                            <option value="TORRE">Torre</option>
                                                            <option value="PLUMA">Pluma</option>
                                                            <option value="MARCOS">Marcos</option>
                                                            <option value="PIES">Pies</option>
                                                            <option value="JAULAS">Jaulas</option>
                                                            <option value="BASES">Bases</option>
                                                            <option value="MECANISMOS">Ganchos/Trepadores/Lastres</option>
                                                        </Field>
                                                        <div className="invalid-feedback">Requerido</div>
                                                    </div>
                                                </div>

                                                {/* Modelos de Grúa */}
                                                {!inventory.id && (
                                                    <div className="col-md-12">
                                                        <div className="form-group form-group-default required">
                                                            <label className="control-label">Modelos de Grúa</label>
                                                            <div
                                                                style={{
                                                                    maxHeight: "150px",
                                                                    overflowY: "auto", 
                                                                    border: "1px solid #ccc",
                                                                    padding: "10px",
                                                                    borderRadius: "5px",
                                                                }}
                                                            >
                                                                {[
                                                                    "5LC5010", "5LC3510", "5LC4510", "10LC140", "10LC110", "10LC1044",
                                                                    "11LC132", "11LC160", "21LC170", "21LC290", "21LC335", "21LC400",
                                                                    "21LC450", "21LC550", "21LC660", "21LC750", "21LC1050", "LCL",
                                                                    "LC5211", "LC2070"
                                                                ].map(model => (
                                                                    <div key={model} className="form-check">
                                                                        <input
                                                                            type="checkbox"
                                                                            id={`crane_model_${model}`}
                                                                            name="crane_model"
                                                                            value={model}
                                                                            className="form-check-input"
                                                                            checked={values.crane_model.includes(model)}
                                                                            onChange={(e) => {
                                                                                const isChecked = e.target.checked;
                                                                                const updatedModels = isChecked
                                                                                    ? [...values.crane_model, model]
                                                                                    : values.crane_model.filter(m => m !== model);
                                                                                setFieldValue("crane_model", updatedModels);
                                                                            }}
                                                                        />
                                                                        <label
                                                                            htmlFor={`crane_model_${model}`}
                                                                            className="form-check-label"
                                                                        >
                                                                            {model}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="invalid-feedback">Requerido</div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* Botones de acción */}
                                        <div className="row mt-4">
                                            <div className="col-md-12">
                                                <button type="submit" className="btn btn-block btn-primary">
                                                    {inventory.id ? "Actualizar pieza" : "Crear pieza"}
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {step === 2 && (
                                    <>
                                        <div className="alert alert-success" role="alert">
                                            {error === null
                                                ? inventory.id
                                                    ? "Pieza actualizada correctamente"
                                                    : "Pieza creada correctamente"
                                                : error}
                                        </div>
                                        <button className="btn btn-block btn-primary" onClick={onClose}>
                                            Listo
                                        </button>
                                    </>
                                )}
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    </div>
);

FormView.propTypes = {
    inventory: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    error: PropTypes.string,
    step: PropTypes.number,
};

export default FormView;
